/* Animações e Transições */
.fade-in {
    animation: fadeIn 0.3s ease-in;
}

.hover-shadow {
    transition: box-shadow 0.3s ease;
}

.hover-shadow:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/* Controles */
.controls-container {
    opacity: 0;
    animation: slideDown 0.5s ease forwards;
}

.control-item {
    transition: transform 0.2s ease;
}

.control-item:hover {
    transform: translateY(-2px);
}

.custom-select {
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
}

.custom-select:hover {
    border-color: #80bdff;
}

.custom-select:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Botão customizado */
.custom-button {
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.custom-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.button-text {
    transition: opacity 0.3s ease;
}

/* Container do Heatmap */
.heatmap-wrapper {
    transition: all 0.3s ease;
}

.heatmap-image {
    transition: transform 0.3s ease;
    cursor: pointer;
}

.heatmap-image:hover {
    transform: scale(1.02);
}

.heatmap-info {
    opacity: 0;
    animation: fadeIn 0.5s ease 0.3s forwards;
}

.info-item {
    transition: color 0.2s ease;
}

.info-item:hover {
    color: #007bff;
}

.info-separator {
    margin: 0 8px;
    color: #dee2e6;
}

/* Animações */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
}

/* Estados de Loading */
.loading-overlay {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
}

/* Responsividade */
@media (max-width: 768px) {
    .heatmap-info {
        flex-direction: column;
        gap: 8px;
    }
    
    .info-separator {
        display: none;
    }
}
